import React, {useState, useEffect , useRef} from "react";
import { Link } from "react-router-dom";
import {Links} from 'routes/PathLink'
import LeftMenuLayout from 'layouts/LeftMenuLayout';

import classNames from 'classnames';
import * as Yup from 'yup';
import {API_ENDPOINT, toast_anim , toast_anim_error,showRating} from 'components/config/Common'
import {Loading} from "components/custom-icons";

import axios from 'axios';  
import {APP_URL, APP_NAME , IMG_URL ,amount_format , ratings} from 'components/config/Common'
import {ChevronLeft, ChevronRight} from "../custom-icons";
import { LazyLoadImage, LazyLoadComponent } from 'react-lazy-load-image-component';
import Helmet from 'react-helmet'
import { ReactSEOMetaTags } from 'react-seo-meta-tags'
import { toast } from 'react-toastify';
import ScrollPageTop from 'components/ui/ScrollPageTop'
import Input from 'components/ui/input.jsx'
import Label from 'components/ui/label.tsx'
import Button from 'components/ui/Button.tsx'
import TextArea from 'components/ui/text-area';
import { Rating } from 'react-simple-star-rating'

// import Swiper core and required modules
import { Navigation, Pagination, Thumbs, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/thumbs';


const upcomingBreakpoints = {
	320: {
	  slidesPerView: 1,
	},
	510: {
	  slidesPerView: 1,
	},
	600: {
		slidesPerView: 2,
	},
	950: {
		slidesPerView: 3,
	},
	1300: {
	  slidesPerView: 3,
	},
};

const HomeReviews = () => {
	
	const [access_token, setAccess_token] = useState(localStorage.getItem('access_token'))
	const [user_type, setUser_type] = useState(localStorage.getItem('user_type'))
	const [user_me, setUser_me] = useState(null);
	const [loading, setLoading] = useState(false);
	//const [ratings,setRatings] = useState([]);

	const upNextRef = useRef(null);
	const upPrevRef = useRef(null)

	return (<div className="w-full bg-light" >
				<div className="px-10 py-10 border-border-200">
				<div className="flex flex-col items-center justify-center">
					<h1 className="mb-4 text-4xl ">Don’t take our word for it!</h1>
					<p className="mb-6 ">Hear from some of our amazing users transforming their life</p>
				</div>				
				<div className="relative flex">
				<Swiper
					// install Swiper modules
					modules={[Navigation, Thumbs]}
					id="reviewsSlider"
					spaceBetween={30}
					watchSlidesProgress={true}
					freeMode={true}
					observer={true}
					observeParents={true}
					breakpoints={upcomingBreakpoints}
					navigation={{
						prevEl: upPrevRef.current, // Assert non-null
						nextEl: upNextRef.current, // Assert non-null
					}}
					>
					{
						ratings && ratings.map((item, key) => (
							<SwiperSlide className="flex  items-start justify-start mb-6 min-h-full bg-gray-100 rounded-2xl" style={{BorderRadous:'20%'}}>
								<div className="border-gray-100 border-[1px] px-2 py-3 rounded-2xl  ">
									<div className="flex flex-col px-2 py-2 pb-14  text-center">
										<img src={`${IMG_URL}support/left-quote-svgrepo-com.png`} className="w-10 h-10" />
										<div className="mt-3">{item.review}</div>
										<div className="flex justify-center">{showRating(item.rating)}</div>
									</div>
									<div className="absolute bottom-0 left-0 bg-white p-2 rounded-tr-lg mt-3 text-accent flex justify-center items-center gap-2">
										<div><img src={`https://ui-avatars.com/api/?name=${item.name}&background=random`} className="w-12 rounded-full" /></div>
										<div>{item.name}</div>
									</div>
								</div>
							
							</SwiperSlide>
						))
					}
					
					</Swiper>
					<div
						ref={upPrevRef}
						className="product-gallery-prev absolute top-2/4 z-10 -mt-4 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-accent text-white shadow-xl transition-all duration-200 hover:text-heading hover:bg-gray-100 ltr:-left-4 rtl:-right-4 md:-mt-5 md:h-9 md:w-9 ltr:md:-left-5 rtl:md:-right-5 dark:bg-gray-500 dark:text-light"
						>
							<ChevronLeft className="h-4 w-4" />
						</div>
					<div
						ref={upNextRef}
						className="product-gallery-next absolute top-2/4 z-10 -mt-4 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-accent text-white shadow-xl transition-all duration-200 hover:text-heading hover:bg-gray-100 ltr:-right-4 rtl:-left-4 md:-mt-5 md:h-9 md:w-9 ltr:md:-right-5 rtl:md:-left-5 dark:bg-gray-500 dark:text-light"
						>
							<ChevronRight className="h-4 w-4" />
					</div>
				</div>
			</div>
			</div>
   );
};

export default HomeReviews